import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

class PressPage extends Component {

  render() {

    return (

    <Layout>
    <SEO title="Certificate of Authenticity" />
    <section className="hero is-medium">
    <div className="hero-body">


      <div className="container">
      <div className="columns is-centered">
      <div className="column is-6 has-text-centered">

        <h1 className="title page-title">
        In the Press
        </h1>
        <p>Some recent Smile-worthy reads.</p>

        </div>

        </div>

      </div>
      </div>


    </section>

  </Layout>
)
    }
  }

export default PressPage
